import axios from 'axios';

const URL_PREFIX = '';
const GIVE_URL = `${URL_PREFIX}/api/9pW7vLMuH5ZVUrSr/`;
const CREATE_GIVE_URL = `${URL_PREFIX}/api/P9tFoP6zMuToU/`;
const CREATE_GIVE_GOLD_URL = `${URL_PREFIX}/api/da3YhnoUdwpjbCd4LjeaRj/`;
const DEMO_PAYMENT_URL = `${URL_PREFIX}/api/RU7VYyr4RuD4d3AqcFv/`;
const GET_COEFF_URL = `${URL_PREFIX}/api/giveaway/coeff/`;


export const apiService = axios.create({
  withCredentials: true,
  xsrfHeaderName: 'X-CSRFToken',
  xsrfCookieName: 'csrftoken',
});


export async function createGivAway(data) {
  try {
    await apiService.post(CREATE_GIVE_URL, data);
    return true;
  } catch {
    return false;
  }
}

export async function CreateGive(data) {
  try {
    const response = await apiService.post(GIVE_URL, data);
    return response.data;
  } catch {
    return { error: true };
  }
}

export async function CreateGiveGold(data) {
  try {
    const response = await apiService.post(CREATE_GIVE_GOLD_URL, data);
    return response.data;
  } catch {
    return { error: true };
  }
}


export async function GetCoeff() {
  try {
    const response = await apiService.get(GET_COEFF_URL);
    return response.data;
  } catch {
    return { error: true };
  }
}

export async function AcceptDemoPayment(id) {
  try {
    const response = await apiService.post(DEMO_PAYMENT_URL, { id });
    return response.data;
  } catch {
    return { error: true };
  }
}
